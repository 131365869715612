<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0">
        <v-card-title class="orange-bg font-weight-bold white--text">
          Orange Reports
        </v-card-title>
        <v-card-text class="mt-3">
          <!-- Start Recent Orange Reports section -->
          <v-row>
            <v-col
              md="4"
              sm="6"
              cols="12"
              v-for="(item, i) in sortedOrangeReports"
              :key="'o' + i"
            >
              <div>
                <p>
                  {{ item.title }}
                  <router-link
                    class="orange--text"
                    :to="
                      `/customerView/${customerId}/orange-reports/${item.id}/view`
                    "
                    >View Orange Report</router-link
                  >
                </p>
                <router-link
                  class="orange--text"
                  :to="
                    `/customerView/${customerId}/orange-reports/${item.id}/view`
                  "
                >
                  <v-img
                    height="200"
                    width="300"
                    :src="reportPicture(item)"
                  ></v-img>
                </router-link>
              </div>
            </v-col>
          </v-row>

          <!-- End Recent Orange Reports section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { GET_QUERY, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 5
    },
    relatedCommunity: null,
    relatedCommunityItems: [],
    orangeReports: [],
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: []
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    options: async function() {
      this.isLoading = true;
      if (this.previousPage < this.options.page && this.options.page !== 1) {
        this.setStartingAfter();
      } else if (
        this.previousPage > this.options.page &&
        this.options.page !== 1
      ) {
        this.setEndingBefore();
      } else {
        this.startingAfter = "";
        this.endingBefore = "";
      }
      this.isLoading = false;
    }
  },
  async mounted() {
    // this.loading = true;
    // await this.getComunityInfo();
    // await this.createBreadcrumb();
    // await this.setInternalBreadcrumbs();
    // this.loading = false;
  },
  async created() {
    await this.getComunityInfo();
    await this.getRecentOrangeReports();
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async getRecentOrangeReports() {
      this.loading = true;

      this.isLoading = true;

      let reportsUrl = `${API_CUSTOMERS}/${this.customerId}/orangeReports`;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: reportsUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.orangeReports = response.data.$values;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Orange Reports" }
      ];
    },

    // async getComunityInfo() {
    //   let items = this.getCustomers();
    //   for (let i = 0; i < items.length; i++) {
    //     if (this.customerId == items[i].id) {
    //       this.communityInfo = items[i];
    //       break;
    //     }
    //   }
    //   items.forEach(element => {
    //     if (
    //       element.contact.name === this.communityInfo.contact.name &&
    //       element.name !== this.communityInfo.name
    //     )
    //       this.relatedCommunityItems.push(element);
    //   });
    // },
    setStartingAfter() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
        this.endingBefore = "";
      }
    },
    setEndingBefore() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.endingBefore = this.userInvoices[0].id;
        this.startingAfter = "";
      }
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Orange Reports" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    reportPicture(report) {
      let defaultPicture =
        "https://cdn.vuetifyjs.com/images/parallax/material2.jpg";
      if (!report) return defaultPicture;

      if (report.photos.$values.length > 0) {
        let imagesToShow = report.photos.$values.filter(i => i.showInReport);
        if (imagesToShow && imagesToShow.length > 0)
          return imagesToShow[0].fileUrl;
        return report.photos.$values[0].fileUrl;
      }

      return defaultPicture;
    }
  },
  computed: {
    totalItems() {
      if (
        (this.userInvoices &&
          this.userInvoices.length === this.options.itemsPerPage &&
          this.previousPage <= this.options.page &&
          this.hasMore) ||
        (this.options.page === 1 &&
          this.userInvoices.length === this.options.itemsPerPage)
      ) {
        return (this.options.page + 1) * this.options.itemsPerPage;
      }
      return this.options.page * this.options.itemsPerPage;
    },
    sortedOrangeReports: function() {
      let items = this.orangeReports.filter(el => el.isApproved);
      //.slice();
      items = items.slice().sort((a, b) => {
        return new Date(b.serviceDate) - new Date(a.serviceDate);
      });
      return items;
    }
  }
};
</script>
